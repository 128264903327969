// 新闻详情
<template>
  <div class="news-details">
    <section class="news-content">
      <h1 class="news-title">{{data.biaoti}}</h1>
      <p class="news-js">
        <span>作者：{{data.zuozhe}}</span>
        <span>来源：{{data.laiyuan}}</span>
        <span>日期：{{data.created_at|filterTime}}</span>
      </p>
      <div class="news-info" v-html="data.neirong"></div>
    </section>
  </div>
</template>

<script>
import { getOneNews } from '@/api'
export default {
  data () {
    return {
      data: {},
    };
  },
  mounted () {
    let queryData = this.$route.query
    this.getData(queryData.type, queryData.id)
  },
  watch: {
    $route (to, from) {
      if (to.meta.isDetails) {
        this.getData(to.query.type, to.query.id)
      }
    }
  },
  methods: {
    async getData (type, id) {
      let { data } = await getOneNews({ type, id })
      this.resetSetItem('news_type', data[0].class_type)
      this.data = data[0]
    }
  },
};
</script>

<style scoped lang="less">
.main-banner {
  margin-bottom: 40px;
}
.news-content {
  .news-title {
    font-size: 31px;
    line-height: 40px;
    margin-bottom: 28px;
    text-align: center;
  }
  .news-js {
    margin: 28px 0;
    text-align: center;
    span {
      margin: 0 20px;
      font-size: 14px;
    }
  }
  /deep/ .news-info {
    p {
      line-height: 30px;
      img {
        margin: 0 auto;
      }
    }
  }
}
</style>
